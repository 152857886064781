import React from "react"

import { Box, Typography, Button } from "@mui/material"
import { HiOutlineArrowNarrowRight } from "react-icons/hi"

import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ArticleInfo = ({ readTime }) => (
  <Box display="flex" gap={1.5}>
    <Typography variant="caption" fontWeight={600}>
      Blog Post
    </Typography>

    <Typography
      variant="caption"
      fontWeight={600}
      color="text.secondary"
      sx={{ borderLeft: "1px solid", borderColor: "text.secondary", pl: 1.5 }}
    >
      {readTime} min read
    </Typography>
  </Box>
)

const FullText = ({ title, subtitle, readTime }) => {
  return (
    <Box
      width={1}
      height={1}
      bgcolor="white"
      borderRadius={1.85}
      position="absolute"
      left={0}
      top={0}
      p={3}
      boxSizing="border-box"
      zIndex={100}
      sx={{
        opacity: 0,
        transition: "opacity 0.5s ",
        "&:hover": {
          opacity: 1,
        },
      }}
    >
      <Box position="relative" width={1} height={1}>
        <ArticleInfo readTime={readTime} />
        <Typography
          variant="body2"
          color="black"
          fontWeight="bold"
          mt={2}
          mb={2}
        >
          {title}
        </Typography>
        <Typography variant="body3" color="black">
          {subtitle}
        </Typography>
        <Button
          aria-label="Read more"
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,

            pl: "0 !important",
            ml: "0 !important",
            color: "primary.main",
            bgcolor: "transparent",
          }}
          size="small"
        >
          Read more{" "}
          <Box component="span" ml={1} mt={1}>
            <HiOutlineArrowNarrowRight size={22} />
          </Box>
        </Button>
      </Box>
    </Box>
  )
}

const FeaturedResource = ({
  resourceURI,
  header: { title, subtitle, image },
  readTime,
  strapi_id: id,
}) => {
  return (
    <Box
      sx={{
        a: { textDecoration: "none !important" },
      }}
    >
      <Link to={`${resourceURI}/${id}`}>
        <Box
          minHeight={430}
          className="featured-resource"
          display="flex"
          flexDirection="column"
          bgcolor="white"
          boxShadow={{
            xs: "rgba(149, 157, 165, 0.2) 0px 1px 3px",
            md: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          }}
          borderRadius={2}
          mb={4}
          ml={4}
          mr={{ xs: 2, md: 4 }}
          position="relative"
          sx={({ shape }) => ({
            "& img": {
              borderRadius: `${shape.borderRadius * 2}px ${
                shape.borderRadius * 2
              }px 0 0 `,
              minHeight: 200,
              maxHeight: 200,
            },
          })}
        >
          {true && (
            <FullText title={title} subtitle={subtitle} readTime={readTime} />
          )}
          <GatsbyImage
            loading="eager"
            image={getImage(image.localFile)}
            alt={title}
          />
          <Box p={3} height={1} flex={1} position="relative">
            <ArticleInfo readTime={readTime} />
            <Typography
              variant="body2"
              color="black"
              fontWeight="bold"
              mt={2}
              mb={4}
            >
              {title}
            </Typography>
            <Button
              aria-label="read-more"
              sx={{
                position: "absolute",
                bottom: 24,
                left: 24,
                alignSelf: "flex-start",
                pl: "0 !important",
                ml: "0 !important",
                color: "primary.main",
                bgcolor: "transparent",
              }}
              size="small"
            >
              Read more{" "}
              <Box component="span" ml={1} mt={1}>
                <HiOutlineArrowNarrowRight size={22} />
              </Box>
            </Button>
          </Box>
        </Box>
      </Link>
    </Box>
  )
}

export default FeaturedResource
